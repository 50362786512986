import React from 'react'
import Footer from '../components/Footer'
import styled from 'styled-components' 
import TopImage1 from "../utils/Images/Chinese Cheongsam Collar Asia Style Top with Cutout Kawaii Harajuku Streetwear Summer Sexy Clubbing Casual Semi Formal Qipao - Black _ One Size.jpg"
import TopImage2 from "../utils/Images/Chinese Style Cheongsam Top Women Summer Sexy Hollow Out Short Sleeve Tops Fashion Embroidered Contrasting - Blue _ L _ Embroidered Cheongsam.jpg"
import TopImage3 from "../utils/Images/SHEIN.jpg"
import TopImage4 from "../utils/Images/werw.jpg"
import TopImage5 from "../utils/Images/image5.jpg"

const Container = styled.div`
 overflow-y: scroll;
  display: flex;
  align-items: center;
   flex-direction: column;
   gap: 50px;
  background: ${({ theme })=> theme.bg}
`;
const Section1 = styled.div`
display: flex;
flex-direction: column;
  max-width: 1400px;
  padding: 16px 92px;
  @media (max-width: 768px){
  padding: 16px 42px;
  }
  `;
const Title = styled.h1`
text-align: start;
@media (max-width: 768px){
text-align: center;
}
@media (max-width: 425px){
font-size:20px;
}
`;
const Image1 = styled.img`
height: 200px;
border-radius: 10px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
  position: absolute;
  border: 2px solid white;

  bottom: 20px;
  right: 50px;
   @media (max-width: 945px){
height: 150px;

}
  @media (max-width: 768px){
display: none;
}

`;
const Image2 = styled.img`
height: 200px;
border-radius: 10px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
  position: absolute;
  border: 2px solid white;

  bottom: 20px;
  right: 280px;
   @media (max-width: 945px){
height: 150px;
  right: 230px;
}
    @media (max-width: 768px){
display: none;
}

`;
const Image3 = styled.img`
height: 200px;
border-radius: 10px;
border: 2px solid white;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
position: absolute;
  top: 20px;
    right: 400px;
     @media (max-width: 945px){
height: 150px;
  right: 325px;
}
 @media (max-width: 768px){
display: none;
}
`;
const Image4 = styled.img`
height: 200px;
border-radius: 10px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
position: absolute;
  top: 20px;
    right: 190px;
    border: 2px solid white;

 @media (max-width: 945px){
height: 150px;
    right: 160px;

}
 @media (max-width: 768px){
display: none;
}
`;
const Section2 = styled.div`
display: flex;
flex-direction: column;
  max-width: 1400px;
  padding: 50px 92px;
    @media (max-width: 768px){
  padding: 16px 42px;
  }
  `;
const Div = styled.div`
display: flex;
justify-content: space-between;
gap: 20px;
 @media (max-width: 768px){
flex-direction: column;
gap:10px;
}
`;
const P = styled.p`
padding-right: 0;
text-align: start;
@media (max-width: 768px){
text-align: center;
}
`;

const P1 = styled.p`
padding-right: 550px;
text-align: start;
 @media (max-width: 945px){
padding-right: 400px;
}
@media (max-width: 768px){
padding-right: 0px;
text-align: center;
}
`;
const Section3 = styled.div`
display: flex;
align-items: center;

  max-width: 1400px;
  padding: 46px 92px;
  gap: 30px;
   @media (max-width: 945px){
  padding: 26px 42px;
}
  @media (max-width: 768px){
flex-direction: column; 
  padding: 26px 12px;
  
}
`;
const Image5 = styled.img`
height: 377px;
border-radius: 10px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.22);
   @media (max-width: 945px){
height: 250px;
}
  @media (max-width: 768px){

height: 250px;
}
@media (max-width: 320px){
height: 150px;
}

`;
const Contact = () => {
  return (
      <Container>
        <Section1 style={{backgroundColor: "GrayText", color: "white", position: "relative"}}>
        
          <Title>About Us</Title>
          <P1>Welcome to YOUNG FIT – a world where fashion meets individuality. Founded with a vision to empower women through clothing, we are more than just a fashion brand; we are a movement that celebrates style, diversity, and self-expression.</P1>
          <div className='divImage'>
          <Image1 src={TopImage1}/>
          <Image2  src={TopImage2}/>
          <Image3 src={TopImage3}/>
          <Image4 src={TopImage4}/>
          </div>
        </Section1>
        <Section2>
          <Title>Our journey began with a simple idea</Title>
          <Div>
            <P>To create a space where women can find outfits that inspire confidence and express their unique personalities. From timeless classics to the latest trends, each piece in our collection is handpicked for its exceptional craftsmanship, comfort, and elegance.</P>
            <P>We are dedicated to sustainability and ethical production, ensuring that our clothes not only make you feel good but do good for the environment too. Whether you're dressing up for an important event or looking for comfortable yet chic everyday wear.</P>
          </Div>
        </Section2>
        <Section3 style={{backgroundColor: "GrayText", color: "white"}}>
        <Image5 src={TopImage5}/>
        <div>
          <Title>We’ve got something for every woman in every moment of her life.</Title>
        <P>Born from a passion for fashion and a desire to make every woman feel confident, [Your Brand Name] was established to offer a versatile wardrobe for women who love to embrace their uniqueness. We understand that clothing is more than fabric – it’s a reflection of your personality, your mood, and your lifestyle. That’s why our collections are thoughtfully designed to bring out the best in every woman, no matter her style or size.</P>
        </div>
        </Section3>
      <Footer/>
      </Container>
  )
}

export default Contact
