import React from 'react'
import styled from 'styled-components'
import Review1 from '../utils/Images/review1.jpg'
import Review2 from '../utils/Images/review2.jpg'
import Review3 from '../utils/Images/review3.jpg'

const ReviewContainer= styled.div`
max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-inline: 50px;
  gap: 28px;
  `;
const H1= styled.h1``;
const ReviewSection= styled.div`
display: flex;
flex-direction: row;
gap:100px;
padding-inline: 50px;
@media (max-width: 768px){
flex-direction: column;
padding-bottom: 0px;
padding-inline: 0px;
gap:20px;
}


`;
const Div= styled.div`
display: flex;
flex-direction: column;
align-items: center;

`;
const Image= styled.img`
width: 70px;
display: flex;
border-radius: 50%;
`;
const Title= styled.h4`
text-align: center;
`;
const P= styled.p`
text-align: center;
`;

const Review = () => {
  return (
    <ReviewContainer style={{backgroundColor: "GrayText", color: "white"}}>
      <H1 style={{textAlign: "center"}}>Reivew</H1>
        <ReviewSection>
      <Div>
        <Image src={Review1}/>
        <Title>Emily S.</Title>
        <P>"I absolutely love shopping at [Your Brand Name]! Their dresses are stunning and fit perfectly. I get compliments every time I wear them. Whether I’m dressing up for a night out or looking for casual daywear, they always have something that fits my style."</P>
      </Div>
      <Div>
        <Image src={Review2}/>
        <Title>Mia R.</Title>
        <P>"I’ve always struggled to find clothes that are both stylish and comfortable, but [Your Brand Name] has it all! I’m especially impressed with their sustainable collections. It’s refreshing to know that I can look good while also supporting ethical fashion."

</P>
      </Div>
      <Div>
        <Image src={Review3}/>
        <Title>Sarah L.</Title>
        <P>"I’m so impressed with the quality of the clothing. The materials feel premium, and the craftsmanship is amazing. On top of that, the customer service is super helpful. They made the process easy from start to finish."

</P>
      </Div>
        </ReviewSection>
    </ReviewContainer>
  )
}

export default Review;
