import React from 'react';
import styled from "styled-components"
// import productImage from "../utils/Images/product3.jpg"
// import { CircularProgress, Rating } from '@mui/material';
// import Button from "../components/Button"
// import { FavoriteRounded } from '@mui/icons-material';
import Footer from "../components/Footer"
import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import {products} from "../utils/products"
// import { filter } from '../utils/data';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    justify-contant: center;
    align-items: center;
    width: 100%;
    height: 90%;
    overflow-y: scroll;
`;
const Wrapper = styled.div`
  flex: 1;
  max-width: 1400px;
  display: flex;
  width: 100%;
  gap: 32px;
  
  @media (max-width: 1300px){
  padding-bottom: 0px;
  padding-inline: 0px;
  
  }
  @media (max-width: 1024px){
    padding: 0px 0px;
  }
  @media (max-width: 875px){

  flex-direction: column;
  justify-content: center;
  }
`;
const ImageWrapper = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
    padding-left: 20px;
padding-block: 20px;

@media (max-width: 768px){
  justify-content: center;
  }
@media (max-width: 636px){
    flex-direction: column;
     padding-left: 10px;
  }
`;
const SmallImageWrapper = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap:20px;
@media (max-width: 768px){
    gap:10px;

  justify-content: center;
  }
  @media (max-width: 636px){
    flex-direction: row;
  }
`;
const Image = styled.img`
    height: 500px;
    border-radius: 12px;
  

    @media (max-width: 768px){
    margin-top: 50px;
    }
    @media (max-width: 556px){
    height: 380px;
    }
    @media (max-width: 400px){
    height: 270px;
    }
`;
const VarientImage = styled.img`
    height: 150px;
    border-radius: 12px;
    @media (max-width: 768px){
    margin-top: 50px;
    height: 110px;
    }
    @media (max-width: 425px){
    height: 90px;
    }
`;

const Details = styled.div`
display: flex;
flex: 1;
gap: 18px;
flex-direction: column;
padding: 14px 20px;
@media (max-width: 768px){
    padding: 14px 50px;
    
    }
@media (max-width: 320px){
    padding: 14px 30px;
    
    }
`;
const Title = styled.div`
font-size: 28px;
font-weight: 600;
color: ${({theme})=> theme.text_primary};
`;
// const Name = styled.div`
// font-size: 28px;
// font-weight: 600;
// color: ${({theme})=> theme.text_primary};
// `;
const Desc = styled.div`
font-size: 16px;
font-weight: 400;
color: ${({theme})=> theme.text_primary};
`;
const Price = styled.div`
display: flex;
align-items: center;
gap: 8px;
font-size: 22px;
font-weight: 500;
color: ${({theme})=> theme.text_primary};
`;
const Span = styled.div`
font-size: 18px;
font-weight: 500;
color: ${({theme})=> theme.text_secondary + 90};
text-decoration: line-through;
text-decoration-color: ${({theme})=> theme.text_secondary + 90};
`;
// const Percent = styled.div`
// font-size: 16px;
// font-weight: 500;
// color: green;
// `;
// const Sizes = styled.div`
//     font-size: 18px;
//     font-weight: 500;
//     display: flex;
//     flex-direction: column;
//     gap: 12px;
// `;
// const Items = styled.div`
//     display: flex;
//     gap: 12px;
// `;
// const Item = styled.div`
//     border: 1px solid ${({theme})=> theme.primary};
//     font-size: 14px;
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     ${({ selected, theme })=>
//     selected && 
//     `
//     background: ${theme.primary};
//     color: white;
//     `}
// `;
// const ButtonWrapper = styled.div`
//     display: flex;
//     gap: 16px;
//     padding: 32px 0px;
// `;

const ProductDetails = () => {
  // const navigate = useNavigate();

  const {pid}= useParams();
  const product= products.find((e)=>{
    return e.id==={pid}.pid;

})
  return (
    <Container>
  <Wrapper>
  <ImageWrapper key={product.id}>
      <Image src={product.img}/>
  <SmallImageWrapper>
    {product.vimg?.map((img)=>(
      <VarientImage src={img}/>
      ))}
      {/* <VarientImage src={product.img1}/>
      <VarientImage src={product.img2}/>
      <VarientImage src={product.img3}/> */}
  </SmallImageWrapper>
  </ImageWrapper>
  <Details>
      <div>
          <Title>{product.name}</Title>
          {/* <Name></Name> */}
      </div>
      {/* <Rating value={3.5}/> */}
      <Price>
          Rs. {product.price}<Span>Rs. {product.mrp}</Span>
          {/* <Percent>40%OFF</Percent> */}
      </Price>
      <Desc><span style={{fontWeight: "700", marginRight: "10px"}}>Description:</span>{product.desc}</Desc>
      {/* <Sizes>
          <Items>
              <Item selected>S</Item>
              <Item>M</Item>
              <Item>L</Item>
              <Item>XL</Item>
              <Item>XXL</Item>
          </Items>
      </Sizes> */}
      {/* <ButtonWrapper>
          <Button text="Add To Cart" full outlined/>
          <Button text="Buy Now" full />
          <Button leftIcon={<FavoriteRounded sx={{ fontSize: "22px", color: "red" }}/>} outlined />
      </ButtonWrapper> */}
  </Details>
</Wrapper> 
<Footer/>
</Container>
  )
}
export default ProductDetails;
