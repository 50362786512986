
import product1 from "../utils/Images/product1.jpg"
import product1_1 from "../utils/Images/product1_1.jpg"
import product1_2 from "../utils/Images/product1_2.jpg"
import product1_3 from "../utils/Images/product1_3.jpg"
import product2 from "../utils/Images/product2.jpg"
import product2_1 from "../utils/Images/product2_1.jpg"
import product2_2 from "../utils/Images/product2_2.png"
import product2_3 from "../utils/Images/product2_3.png"
import product3 from "../utils/Images/product3.jpg"
import product3_1 from "../utils/Images/product3_1.jpg"
import product3_2 from "../utils/Images/product3_2.jpg"
import product3_3 from "../utils/Images/product3_3.png"
import product4 from "../utils/Images/product4.jpg"
import product4_1 from "../utils/Images/product4 _1.jpg"
import product4_2 from "../utils/Images/product4 _2.jpg"
import product4_3 from "../utils/Images/product4 _3.jpg"
import product5 from "../utils/Images/product5.jpg"
import product5_1 from "../utils/Images/product5_1.png"
import product5_2 from "../utils/Images/product5_2.png"
import product5_3 from "../utils/Images/product5_3.jpeg"
import product6 from "../utils/Images/Graphic Print Crop Top.jpeg"
import product6_1 from "../utils/Images/Graphic Print Crop Top1.jpeg"
import product6_2 from "../utils/Images/Graphic Print Crop Top2.jpeg"
import product6_3 from "../utils/Images/Graphic Print Crop Top3.jpeg"
import product7 from "../utils/Images/product7.jpg"
import product8 from "../utils/Images/product8.jpg"
import product8_1 from "../utils/Images/product8_1.jpg"
import product8_2 from "../utils/Images/product8_2.jpg"
import product9 from "../utils/Images/product9.jpg"
import product9_1 from "../utils/Images/product9_1.jpg"
import product9_2 from "../utils/Images/product9_2.jpg"
import product10 from "../utils/Images/product10.jpg"
import product10_1 from "../utils/Images/product10_1.jpg"


export const products = [
  {
    id: "1",
    img: product1,
    vimg:[
     product1_1,
 product1_2,
 product1_3
],
    name: "Casual Wear Chinese Top",
    desc: "20-40% OFF",
     price:"450",
    mrp: "500"
  },
  {
    id: "2",
    img: product2,
    vimg:[
    product2_1,
    product2_2,
   product2_3
],
    name: "Classie Women Top",
    desc: "10-20% OFF",
         price:"450",
    mrp: "500"

  },
  {
    id: "3",
    img: product3,
    vimg:[
    product3_1,
   product3_2,
     product3_3
],
    name: "Top",
    desc: "20-40% OFF",
     price:"450",
    mrp: "500"

  },
  {
    id: "4",
    img: product4,
    vimg:[
   product4_1,
     product4_2,
    product4_3
],
    name: "Top",
    desc: "30-40% OFF",
     price:"450",
    mrp: "500"

  },
  {
    id: "5",
    img: product5,
    vimg:[
     product5_1,
     product5_2,
    product5_3
    ],
    name: "Top",
    desc: "10-40% OFF",
     price:"450",
    mrp: "500"

  },
  {
    id: "6",
    img: product6,
   vimg:[
     product6_1,
     product6_2,
     product6_3
],
    name: "Graphic Print Crop Top",
    desc: "The Graphic Print Crop Top – a seamless symphony of comfort and style. Made of 100% cotton, this crop top is characterised by a unique graphic print on the chest. Its round neckline complements its design, making it a perfect match with denim or sweatpants.",
     price:"450",
    mrp: "500"

  },
  {
    id: "7",
    img: product7,
    
    name: "women streetstylestore Brown candy crop top",
    desc: "The Graphic Print Crop Top – a seamless symphony of comfort and style. Made of 100% cotton, this crop top is characterised by a unique graphic print on the chest. Its round neckline complements its design, making it a perfect match with denim or sweatpants.",
     price:"450",
    mrp: "500"

  },
  {
    id: "8",
    img: product8,
    vimg: [
    product8_1,
    product8_2
    ],
    name: "Spoiled Purple Crop Top",
    desc: "The Graphic Print Crop Top – a seamless symphony of comfort and style. Made of 100% cotton, this crop top is characterised by a unique graphic print on the chest. Its round neckline complements its design, making it a perfect match with denim or sweatpants.",
    price:"450",
    mrp: "500"

  },
  {
    id: "9",
    img: product9,
    vimg:[
    product9_1,
    product9_2
    ],
    name: "Newlan DX006 Korean Crop Top Rib Sexy Crop Top Kaos Wanita Ava Basic Baju Crop Top Fashion",
    desc: "The Graphic Print Crop Top – a seamless symphony of comfort and style. Made of 100% cotton, this crop top is characterised by a unique graphic print on the chest. Its round neckline complements its design, making it a perfect match with denim or sweatpants.",
    price:"450",
    mrp: "500"

  },
  {
    id: "10",
    img: product10,
    vimg:[
    product10_1
  ],
    name: "women streetstylestore Floral Print Tie-Up Cute Crop Top",
    desc: "The Graphic Print Crop Top – a seamless symphony of comfort and style. Made of 100% cotton, this crop top is characterised by a unique graphic print on the chest. Its round neckline complements its design, making it a perfect match with denim or sweatpants.",
    price:"450",
    mrp: "500"

  },
];

